import axios from 'axios'

const baseURL = 'https://scratch-card-dot-acerta-sistema-prod.appspot.com/scratch-card'

const api = axios.create({
  baseURL,
})

api.interceptors.request.use(async (config) => {
  if(config.headers) {    
      config.headers['x-api-auth-key'] = process.env.REACT_APP_API_KEY || ''
  }

  return config
})

export default api
