import api from '../../config/api'

import { CampaignDrawInterface } from '../../models/interfaces/services/CampaignDraws'

const basePath = '/v1/campaign-draws'

export const getByProposalNumber = async (
  proposalNumber: string,
): Promise<CampaignDrawInterface> => {
  return await api
    .get<CampaignDrawInterface>(`${basePath}/get-by/proposal/${proposalNumber}`)
    .then(({data}) => data)
}

export const getAllGiftsByProposaNumberAndDelivered = async (
  proposalNumber: string,
): Promise<CampaignDrawInterface> => {
  return await api
    .put<CampaignDrawInterface>(`${basePath}/delivered/proposal/${proposalNumber}`)
    .then(({data}) => data)
}

export const getAllGiftsByProposaNumberAndCampaignSlugAndDelivered = async (
  proposalNumber: string,
  campaignSlug: string,
): Promise<CampaignDrawInterface> => {
  return await api
    .put<CampaignDrawInterface>(`${basePath}/delivered/proposal/${proposalNumber}/campaign/${campaignSlug}`)
    .then(({data}) => data)
}
