import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'

import MainTemplate from '../components/templates/Main'


import SractchCard from '../pages/ScratchCard'

const routes = () => (
  <Router>
    <Switch>
      <Route exact path="/:proposalId">
        <MainTemplate>
          <SractchCard />
        </MainTemplate>
      </Route>
      <Route exact path="/:campaignSlug/:proposalId">
        <MainTemplate>
          <SractchCard />
        </MainTemplate>
      </Route>
      <Route exact path="*">
        <MainTemplate>
          <SractchCard />
        </MainTemplate>
      </Route>
      </Switch>
  </Router>
)

export default routes
