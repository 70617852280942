import React from 'react'
import './styles.css'

const Loading:React.FC = () => (
  <div className="lds-grid">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default Loading
