import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ScratchCard from 'react-scratchcard'

import * as CampaignDrawsService from '../../services/CampaignDraws'

import { CampaignDrawInterface } from '../../models/interfaces/services/CampaignDraws'

import Loading from '../../components/loading/'

import ImgScratch from '../../assets/img/raspadinha/raspeaqui.png'
import ImgHeader from '../../assets/img/raspadinha/RASPPOU_GANHOU.png'
import ImgAcertaLogo from '../../assets/img/raspadinha/logo_viacerta.png'
import '../../index.css'


const settings = {
  width: 280,
  height: 155,
  image: ImgScratch,
  finishPercent: 90,
  onComplete: () => console.log("Raspadinha ok")
};

interface RouteParamsInterface {
  campaignSlug?: string
  proposalId?: string
}

const ScratchCardPage: React.FC = () => {

  const { campaignSlug, proposalId } = useParams<RouteParamsInterface>();

  const [draw, setDraw] = useState<CampaignDrawInterface>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const getDraw = () => {
      if (!proposalId) return

      if (campaignSlug) {
        CampaignDrawsService.getAllGiftsByProposaNumberAndCampaignSlugAndDelivered(
          proposalId, campaignSlug,
        ).then((response) => {
          setDraw(response)
          setIsLoading(false)
        })
      } else {
        CampaignDrawsService.getAllGiftsByProposaNumberAndDelivered(
          proposalId,
        ).then((response) => {
          setDraw(response)
          setIsLoading(false)
        })
      }
    }

    getDraw()
  }, [proposalId, campaignSlug])

  const Raspadinha = () => (
    <div className="raspadinha">
      <ScratchCard {...settings}>
        <p style={{ color: "#fff" }}>
          <b>{draw!.gift!.message}</b>
        </p>
      </ScratchCard>
    </div>
  );

  return (
      <div className="container">
      <header>
        <img className="img-header" src={ImgHeader} alt="" />
      </header>
      <main>
        <div className="container-raspadinha">
          {isLoading && <Loading />}
          {draw && draw.gift && <Raspadinha />}
        </div>
      </main>
      <footer>
        <img src={ImgAcertaLogo} alt="" />
      </footer>
    </div>
  )
}

export default ScratchCardPage
